/**
共通ライブラリ
*/
'use strict';

import riot  from 'riot';
import route from 'riot-route';
window.route = route
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);
//require('../js/connect.js')

window.riot = riot
window.UIkit = UIkit

const $ = require("jquery");
window.$ = $
window.jQuery = $

var obs = riot.observable()
window.obs = obs

// loadingを表示
function activeLoading(message = null) {
  obs.loading = true
  let loadingDom = "<div id='loading' class='uk-width-1-1 uk-text-center uk-position-cover uk-flex uk-flex-center uk-flex-middle' height='window.outerHeight' style='z-index: 300; opacity: 0.5; position: fixed; background-color: #fff;'><div uk-spinner></div></div>"
  if (message) {
    loadingDom += `<p>${message}</p>`
  }
  $('body').append(loadingDom)
}
window.activeLoading = activeLoading

// loadingを非表示
function passiveLoading() {
  obs.loading = false
  $('#loading').remove()
}
window.passiveLoading = passiveLoading

// tokenをcookieに保存する
function setToken(token) {
  obs.token       = token
  document.cookie = `codioToken=${token}`
}
window.setToken = setToken

// tokenをcookieから取り出す
function getToken() {
  if(document.cookie.length>0){
    // クッキーの値を取り出す
    let st=document.cookie.indexOf('beatFitToken' + "=");
    if(st!=-1){
      st=st+'beatFitToken'.length+1;
      let ed=document.cookie.indexOf(";",st);
      if(ed==-1) ed=document.cookie.length;
      // 値をデコードして返す
      return document.cookie.substring(st,ed);
    }
  }
}
window.getToken = getToken

// cookieからtokenの情報を消す
function deleteCookie() {
  document.cookie = `beatFitToken=del; max-age=0`
}
window.deleteCookie = deleteCookie
